/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import Footer from "./footer"
import Header from "./header"
import "./layout.css"

const Layout = props => {
  const isRiver = "isRiver" in props
  const { children, location } = props
  return (
    <div className="wrapper">
      <Header location={location} />
      <div className="main">
        <div className={isRiver ? "container full" : "container"}>
          {/* {isFilter ? <Filters /> : null} */}
          {children}
        </div>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
