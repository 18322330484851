import React, { useState, useEffect, useRef } from "react"

import { navigate, Link, useStaticQuery, graphql } from "gatsby"
const handleLogin = () => {
  window.location.href = "https://addfriends.com/vip?cb=" + new Date().getTime()
}
const Header = () => {
  const theSearchInput = useRef(null)

  const [isSearch, setIsSearch] = useState(false)
  const [mobileMenu, setMobileMenu] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [isDarkMode, setIsDarkMode] = useState(false)
  useEffect(() => {
    if (mobileMenu) {
      document.body.classList.add("open")
    } else {
      document.body.classList.remove("open")
    }
  }, [mobileMenu])

  const data = useStaticQuery(graphql`
    {
      allMysqlSites(filter: { show_tour: { eq: "y" } }) {
        edges {
          node {
            site_name
            site_url
          }
        }
      }
    }
  `)
  const flatModels = data.allMysqlSites.edges.map(edge => {
    return { site_name: edge.node.site_name, site_url: edge.node.site_url }
  })
  const handleSearchSelect = item => {
    navigate(`/${item.url}/river`)
    setSearchResults([])
    setSearchTerm("")
    setIsSearch(false)
  }
  const handleSearching = e => {
    const newSearchTerm = e.target.value.toLowerCase()
    setSearchTerm(newSearchTerm)
    setSearchResults(
      flatModels
        .filter(
          model => model.site_name.toLowerCase().indexOf(newSearchTerm) !== -1
        )
        .map(model => {
          const l = newSearchTerm.length
          const w = model.site_name.length
          const s = model.site_name.toLowerCase().indexOf(newSearchTerm)
          return {
            modelName: model.site_name,
            start: s,
            end: l,
            whole: w,
            url: model.site_url,
          }
        })
    )
  }
  useEffect(() => {
    if (isSearch) {
      setTimeout(() => theSearchInput.current.focus(), 222)
    }
  }, [isSearch])
  useEffect(() => {
    // const isLight = localStorage.getItem("lightv2") === "true"
    // if (isLight) {
    //   document.body.classList.add("choice")
    //   setIsDarkMode(false)
    // }
    const isLight = localStorage.getItem("lightv2") === "false"
    if (isLight) {
      document.body.classList.remove("choice")
      setIsDarkMode(false)
    }
  }, [])
  const handleThemeChoice = () => {
    if (isDarkMode) {
      localStorage.setItem("lightv2", "true")
      document.body.classList.add("choice")
    } else {
      localStorage.setItem("lightv2", "false")
      document.body.classList.remove("choice")
    }
    setIsDarkMode(!isDarkMode)
  }
  return (
    <>
      <div className={isSearch ? "header searchView" : "header"}>
        <div className="column">
          <a href={`/vip?cb=${new Date().getTime()}`} className="button">
            <span>Login</span>
          </a>
        </div>
        <div className="column second">
          <div className="hamburger">
            <button
              type="button"
              className="button_hamburger"
              id="hamburger"
              onClick={() => setMobileMenu(m => !m)}
            >
              <span />
            </button>
          </div>
          <div className="logo">
            <Link to="/">
              +Add<strong>Friends</strong>
            </Link>
          </div>
        </div>
        <div className="column last">
          {/* <button
            type="button"
            className="button_item button_search"
            onClick={() => setIsSearch(s => !s)}
          >
            <i className="icon-search" />
          </button> */}
          {/* {typeof location !== "undefined" && location.pathname === "/" ? (
            <button
              type="button"
              className="button_item button_filter"
              onClick={() => setIsFilter(s => !s)}
            >
              <i className="icon-filter" />
            </button>
          ) : null} */}

          {/* <button
            type="button"
            className="button_item"
            onClick={() => navigate("/how-to")}
          >
            <i className="icon-ask" />
          </button> */}
          <button
            type="button"
            className="button_item button_theme"
            onClick={handleThemeChoice}
          >
            <i className="icon-moon" />
          </button>
        </div>
        <div className="search_header">
          <form action="#">
            <div className="search_columns">
              <div className="search_column second">
                <button type="button" className="btn_search">
                  <i className="icon-search" />
                </button>
              </div>
              <div className="search_column">
                <input
                  type="text"
                  className="input"
                  id="input"
                  ref={theSearchInput}
                  onChange={handleSearching}
                  value={searchTerm}
                  placeholder="Search"
                />
              </div>
              <div className="search_column second">
                <button
                  type="button"
                  className="btn_search"
                  onClick={() => setIsSearch(false)}
                >
                  <i className="icon-close" />
                </button>
              </div>

              {isSearch && searchResults.length > 0 ? (
                <div className="autocomplete-suggestions">
                  {searchResults.map((item, i) => (
                    <div className="autocomplete-suggestion" key={`srr${i}`}>
                      {item.start === 0 ? (
                        <div onClick={() => handleSearchSelect(item)}>
                          <b>{item.modelName.slice(0, item.end)}</b>
                          {item.modelName.slice(item.end)}
                        </div>
                      ) : (
                        <div onClick={() => handleSearchSelect(item)}>
                          {item.modelName.slice(0, item.start)}
                          <b>
                            {item.modelName.slice(
                              item.start,
                              item.start + item.end
                            )}
                          </b>
                          {item.modelName.slice(item.start + item.end)}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </div>

      <div className="dropdown_menu">
        {/* <button
          type="button"
          className="item_drop button_filter"
          onClick={() => setMobileFilters(f => !f)}
        >
          <i className="icon-filter"></i>
          <span>Filters</span>
          <i className="icon-arrow-down"></i>
        </button> */}
        <div className="filter_dropdown">
          <div className="row">
            <div className="wrapper_section">
              <span className="value">Categories</span>
              <div className="filter_button">
                <span>Coming Soon</span>
                <i className="icon-arrow-down"></i>
              </div>
            </div>
            {/* <div className="filter_dropped">
              <a href="#" className="item_row">
                <span>Babes</span>
                <em>56,890</em>
              </a>
              <a href="#" className="item_row">
                <span>Star</span>
                <em>56,890</em>
              </a>
              <a href="#" className="item_row">
                <span>Nude</span>
                <em>56,890</em>
              </a>
              <a href="#" className="item_row">
                <span>Pornstar</span>
                <em>56,890</em>
              </a>
              <a href="#" className="item_row">
                <span>Cams</span>
                <em>56,890</em>
              </a>
              <a href="#" className="item_row">
                <span>Private Video</span>
                <em>56,890</em>
              </a>
            </div> */}
          </div>
          <div className="row">
            <div className="wrapper_section">
              <span className="value">Sort by</span>
              <div className="filter_button">
                <span>Coming Soon</span>
                <i className="icon-arrow-down"></i>
              </div>
            </div>
            {/* <div className="filter_dropped">
              <a href="#" className="item_row">
                <span>Babes</span>
                <em>56,890</em>
              </a>
              <a href="#" className="item_row">
                <span>Star</span>
                <em>56,890</em>
              </a>
              <a href="#" className="item_row">
                <span>Nude</span>
                <em>56,890</em>
              </a>
              <a href="#" className="item_row">
                <span>Pornstar</span>
                <em>56,890</em>
              </a>
              <a href="#" className="item_row">
                <span>Cams</span>
                <em>56,890</em>
              </a>
              <a href="#" className="item_row">
                <span>Private Video</span>
                <em>56,890</em>
              </a>
            </div> */}
          </div>
          <div className="row">
            <span className="value">Filter by</span>
            <div className="social_list">
              <a
                href="https://mobile.twitter.com/AddFriendscom"
                className="social_item"
              >
                <i className="icon-twitter"></i>
              </a>
              <a
                href="https://www.snapchat.com/add/addfriendsnow"
                className="social_item"
              >
                <i className="icon-snapchat"></i>
              </a>
              <a
                href="https://www.instagram.com/addfriendsnow/"
                className="social_item"
              >
                <i className="icon-inst"></i>
              </a>
            </div>
          </div>
        </div>
        <button
          type="button"
          className="item_drop button_theme"
          onClick={handleThemeChoice}
        >
          <i className="icon-moon"></i>
          <span>Night mode2</span>
        </button>
        <button
          type="button"
          className="item_drop"
          onClick={() => navigate("/friends")}
        >
          <i className="icon-models"></i>
          <span>Explore</span>
        </button>
        <button type="button" className="item_drop" onClick={handleLogin}>
          <i className="icon-log-in"></i>
          <span>Login</span>
        </button>
        <button type="button" className="item_drop help">
          <span
            style={{
              marginTop: 5,
              fontWeight: "lighter",
              textAlign: "center",
              textDecoration: "underline",
            }}
            onClick={() => navigate("/join-application")}
          >
            Become A Creator
          </span>
        </button>
      </div>
    </>
  )
}

export default Header
